<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>

    <div id="beian_im" style="width:350px;margin:0 auto;overflow: hidden;">
        <a target="_blank" href="https://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;float: left">
            <img src="https://s.momocdn.com/w/u/others/2017/10/20/1508498819600-%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png" style="float:left;width: 16px;margin-top: 3px;">
            <span style="float: left">京ICP备2021036430号-1</span>
        </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
